import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const Page = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
  <Layout>
    <SEO title={ frontmatter.title } description={ frontmatter.description } keywords={ frontmatter.tags || [ 'journalism`, `art`, `culture', 'conflict' ] } />
    <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
  </Layout>
  )
}

export default Page

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        description
        path
        date
        tags
      }
    }
  }
`